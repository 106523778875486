import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layoutLanding"
import SEO from "../components/seo"
import styles from "./index.module.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`home`, `architecture`, `architectural services`]} />
    <div className={`d-flex justify-content-center ${styles.services}`}>
      <h2 className={styles.service}>
        <Link
          style={{ textDecoration: `none` }}
          to="/work/exteriors"
          className="text-light"
        >
          Exteriors
        </Link>
      </h2>
      <h2 className={styles.service}>
        <Link
          style={{ textDecoration: `none` }}
          to="/work/interiors"
          className="text-light"
        >
          Interiors
        </Link>
      </h2>
      <h2 className={styles.service}>
        <Link
          style={{ textDecoration: `none` }}
          to="/work/walkthroughs"
          className="text-light"
        >
          Walkthroughs
        </Link>
      </h2>
      <h2 className={styles.service}>
        <Link
          style={{ textDecoration: `none` }}
          to="/work/360"
          className="text-light"
        >
          360
        </Link>
      </h2>
    </div>
  </Layout>
)

export default IndexPage
