import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import "../styles/main.scss"
import Header from "./header"
import Footer from "./footerLanding"
import "./layout.scss"
import BackgroundImage from "gatsby-background-image"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "backgroundImg.jpeg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `space-between`,
              minHeight: `100vh`,
            }}
          >
            <Header logo={`light`} text={`text-light`} />
            <div
              style={{
                margin: `0 auto`,
                width: `100%`,
                maxWidth: 1140,
                padding: `0px 1.0875rem 1.45rem`,
                paddingTop: 0,
              }}
            >
              <main>{children}</main>
            </div>
            <Footer />
          </div>
        </BackgroundImage>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
