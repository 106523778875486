import React from "react"
import styles from "./footer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  return (
    <footer className={`${styles.footer} row text-secondary`}>
      <div className={`col-sm-12 col-md-4 col-lg-6 ${styles.socialIcons}`}>
        <a
          className="text-light"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/PHOENIXSTUDIO2014/"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a
          className="text-light"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UCcWZ5UiwLpMACymI4O2pHVg?view_as=subscriber"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a
          className="text-light"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/phoenix-architecture-642191186/"
        >
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
      </div>
      <div className={`col-sm-12 col-md-8 col-lg-6 ${styles.mail}`}>
        <a
          className="text-light"
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:services.phoenixarchitecture@gmail.com"
        >
          <FontAwesomeIcon className={styles.envelope} icon={faEnvelope} />
          <strong>services.phoenixarchitecture@gmail.com</strong>
        </a>
      </div>
    </footer>
  )
}

export default Footer
